/* BUTTONS */

:root {
  --amplify-widget-orange: #ff9900;
  --amplify-widget-grey: #c4c4c4;
}

.amplify-button--primary {
  background: var(--amplify-widget-orange);
}

.amplify-button--primary:hover {
  background: var(--amplify-widget-orange);
  opacity: 0.8;
  box-shadow: none;
}

.amplify-button--primary:focus {
  background: var(--amplify-widget-orange);
  opacity: 0.8;
  box-shadow: none;
}

.amplify-button--primary:active {
  background: var(--amplify-widget-orange);
  opacity: 0.8;
  box-shadow: none;
}

.amplify-button--loading {
  background: var(--amplify-widget-orange);
  opacity: 0.8;
  box-shadow: none;
  color: #fff;
}

.amplify-button--small {
  color: var(--amplify-widget-orange);
}

.amplify-button--small:hover {
  color: var(--amplify-widget-orange);
  opacity: 0.8;
  background: transparent;
}

.amplify-button--small:focus {
  color: var(--amplify-widget-orange);
  opacity: 0.8;
  background: transparent;
  outline: none;
  border: none;
}

.amplify-field__show-password {
  border: 1px solid var(--amplify-widget-grey);
  box-shadow: none;
}

.amplify-field__show-password:hover {
  border: 1px solid var(--amplify-widget-grey);
  background: transparent;
  color: var(--amplify-widget-orange);
  box-shadow: none;
}

.amplify-field__show-password:focus {
  border: 1px solid var(--amplify-widget-grey);
  background: transparent;
  color: var(--amplify-widget-orange);
  box-shadow: none;
  border-inline-start-color: transparent;
}

/* INPUTS */

.amplify-input {
  border: 1px solid var(--amplify-widget-grey);
  border-radius: 3px;
  padding: 16px;
  transition: none;
}

.amplify-input:focus {
  border: 1px solid var(--amplify-widget-grey);
  outline: none;
  box-shadow: none;
}

.amplify-input::placeholder {
  font-size: 14px;
}

/* OTHER */

.amplify-label {
  font-size: 14px;
  margin-bottom: 8px;
}

.amplify-heading {
  font-size: 18px;
}

.header {
  padding: 2rem 2rem 0;
}

/* FORMS */

[data-amplify-authenticator] [data-amplify-router] {
  border: none;
  border-radius: 5px;
}

[data-amplify-authenticator] [data-amplify-form] {
  padding: 1.5rem 2rem 2rem;
}

form[data-amplify-authenticator-signin] {
  padding-bottom: 10px !important;
}
